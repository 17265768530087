<template>
  <div class="main">
    <form @submit.prevent="openReport()" method="post">
      <table border="0" width="100%">
        <tbody>
          <tr>
            <td><span for="name">姓名:</span></td>
            <td width="70%"><input type="text" id="name" v-model="formData.name"  placeholder="请填写"   required /></td>
          </tr>
          <tr>
            <td><span for="phone">电话:</span></td>
            <td><input type="text" id="phone" v-model="formData.phone"  placeholder="请填写"  required /></td>
          </tr>
          <tr>
            <td><span for="sampleCode">样本条码:</span></td>
            <td><input type="text" id="sampleCode" v-model="formData.sampleCode"  placeholder="请填写"   required /></td>
          </tr>
        </tbody>
      </table>
      <button type="submit">查询</button>
    </form>

    <ul v-if="Samplearray&&Samplearray.length > 0">
      <li v-for="(samp, index) in Samplearray" :key="index">
        <label>报告：</label>{{ samp.sampleCode }}
        <button class="report-button" @click="setOpenReport(samp)">查询报告</button>
      </li>
    </ul>
    <ul v-else>
      <li>请提取样本后，使用添加检测功能绑定检测盒。</li>
    </ul>

    

    <!-- 弹出层 -->
    <div id="reportbox" v-if="isReportBoxVisible" @click="closeReportBox">
      <div class="report-content" @click.stop>
        <div class="report-header"> <img  src="../assets/ico4591@3x.png" width="40px"><label>检测报告</label></div>
        <button class="close-button" @click="closeReportBox">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 45.6 45.6001">
	<path transform="matrix(1 0 0 1 0 0)" d="M22.8 0C10.2079 0 0 10.2079 0 22.8L0 22.8342C0 35.4263 10.1737 45.6 22.7658 45.6L22.8 45.6C35.3921 45.6 45.6 35.4263 45.6 22.8342L45.6 22.8C45.6 10.2079 35.4263 0 22.8342 0L22.8 0Z" fill-rule="nonzero" fill="rgb(255, 255, 255)" fill-opacity="0.7"/>
	<path transform="matrix(1 0 0 1 0 0)" d="M-0 22.8C0 10.2079 10.2079 -0 22.8 -0C35.3921 0 45.6 10.2079 45.6 22.8C45.6 35.3921 35.3921 45.6 22.8 45.6C10.2079 45.6 -0 35.3921 -0 22.8L0 22.8ZM22.8 1.2C10.8706 1.2 1.2 10.8706 1.2 22.8C1.2 34.7294 10.8706 44.4 22.8 44.4C34.7294 44.4 44.4 34.7294 44.4 22.8C44.4 10.8706 34.7294 1.2 22.8 1.2L22.8 1.2Z" fill-rule="nonzero" fill="rgb(255, 255, 255)"/>
	<g opacity="0.6">
		<path transform="matrix(0.707107 0.707107 -0.707107 0.707107 14.9984 11.7811)" d="M0 2C-1.19209e-07 3.10456 0.89544 4 2 4L12.3082 4C13.4128 4 14.3082 3.10456 14.3082 2C14.3082 0.895441 13.4128 9.53674e-07 12.3082 8.34465e-07L2 0C0.89544 -1.19209e-07 0 0.89544 0 2ZM17.1669 2.00738C17.1669 3.11194 18.0623 4.00738 19.1669 4.00738L24.7879 4.00738C25.8924 4.00738 26.7879 3.11194 26.7879 2.00738C26.7879 0.902821 25.8924 0.0073806 24.7879 0.00738049L19.1669 0.00738001C18.0623 0.00737989 17.1669 0.90282 17.1669 2.00738Z" fill-rule="evenodd" fill="rgb(36, 53, 65)"/>
		<path transform="matrix(0.707107 0.707107 -0.707107 0.707107 30.8817 14.376)" d="M0.316667 -2C-0.787893 -2 -1.68333 -1.10456 -1.68333 0L-1.68333 22.8C-1.68333 23.9046 -0.787893 24.8 0.316667 24.8C1.42123 24.8 2.31667 23.9046 2.31667 22.8L2.31667 0C2.31667 -1.10456 1.42123 -2 0.316667 -2Z" fill-rule="evenodd" fill="rgb(36, 53, 65)"/>
	</g>
</svg>
</button>

<div class="reportsdivhi"  v-if="reports&&reports.length > 0">
  <div class="reportsdiv"  v-for="(report, index) in reports" :key="index">
      <table  border="0" width="100%">
        <tbody>
          <tr>
            <td>
              <b>{{ report.name }}</b>
              <br />
              <span>出生日期：</span>{{ report.birth }}<br />
              <span>报告编号：</span>{{ report.sampleReportCode }}
            </td>
            <td>
              <br />
            <button  v-if="report.adopt!=1" class="unable-button">检测中...</button>
             <a :href="report.reportUrl" v-if="report.adopt==1" class="report-button" target="_blank">查看报告</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>


        <table v-else-if="reportsmsg&&reportsmsg.length > 0" border="0"  width="100%">
          <tbody>
            <tr><td>正在检测中:</td></tr>
            <tr v-for="(report, index) in filteredReportsmsg" :key="index">
              <td>{{ index+1 }}. {{ report.stateStr }}</td>
              <td>状态：{{ report.stateFlag ? '完成' : '进行中' }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else>{{ this.reporterrmsg }}</p>
        
   
    </div>
  
      </div>

  </div>
</template>
<script>
import axios from 'axios';


export default {
  name: 'IndexPage',
  props: {
    msg: String
  },
  data() {
    return {
      Samplearray: [],
      reports: [],
      reportsmsg: [],
      reporterrmsg:'',
      formData: {
        name: '',
        phone: '',
        cpid: '',
        sampleCode: '',
        openId: ''
      },
      isReportBoxVisible: false,
      selectedReport: null
    };
  },
  computed: {
    filteredReportsmsg() {
      return this.reportsmsg.filter(report => report.stateStr && report.stateFlag);
    }
  },
  created() {
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      const data = JSON.parse(savedData);
      this.formData.name = data.name;
      this.formData.phone = data.phone;
    }
    // 从 URL 参数中获取 sampleCode 并赋值给 formData.sampleCode
    this.formData.sampleCode = this.$route.query.sampleCode || '';
    if (this.formData.sampleCode) {
      this.openReport();
    }
 
  },
  mounted() {
    // 从 localStorage 加载数据
    var retrievedArray = localStorage.getItem('arrayKey');
    retrievedArray = JSON.parse(retrievedArray);
    if (retrievedArray) {
      this.Samplearray = retrievedArray;
    }
    },
  methods: {
    async fetchReports() {

       
      try {
        const responsecheckSample = await axios.post(
          `${process.env.VUE_APP_API_URL}/omicsfit/checkSample?sampleCode=${this.formData.sampleCode}`,
          {}
        );
        // console.log('获取报告状态:', responsecheckSample.data);
        // 判断是否为已检测状态,未检测转到绑定页（data=='2'）
        if (responsecheckSample.data=='2') {
          this.reporterrmsg = "条码未绑定，请先绑定检测人员信息！";
          alert('条码未绑定，请先绑定检测人员信息！');

          const data = {
            name: this.formData.name,
            phone: this.formData.phone,
            openId: this.$globalVar.userData.openId, 
            sampleCode: this.formData.sampleCode
          };
          // console.log('获取报告状态:', data);
          // 保存数据到 localStorage
          localStorage.setItem('formData', JSON.stringify(data));
          //跳转到添加检测人员信息页面
          this.$router.push('/new');   
          return       
        }else if (responsecheckSample.data=='5') {
          // 判断是否完成检测（data=='5'）
          const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/omicsfit/reportList?name=${this.formData.name}&phone=${this.formData.phone}&openid=${this.$globalVar.userData.openId}&sampleCode=${this.formData.sampleCode}`,
          {}
        );
        // console.log('获取报告成功:', response.data);
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.reports = response.data;
          this.isReportBoxVisible = true;

          //查询到正确的报告，存到缓存localStorage中。 
          var rdata = {
            name: response.data[0].name,
            phone: response.data[0].phone,
            cpid: response.data[0].cpid, // 根据 curl 请求，cpid 和 phone 是相同的
            sampleCode:this.formData.sampleCode ,
            sex: response.data[0].sex,
            birth: response.data[0].birth,
            birthAddress: response.data[0].birthAddress
          }
          // console.log('获取rdata:', rdata);
            var retrievedArray = localStorage.getItem('arrayKey');
            retrievedArray = JSON.parse(retrievedArray);
            if(retrievedArray){
              for (let index = 0; index < retrievedArray.length; index++) {
                if(rdata.sampleCode==retrievedArray[index].sampleCode){
                  return;
              }
                
              }
              retrievedArray.push(rdata);
            }else{
                retrievedArray = [];
                retrievedArray.push(rdata);
              }
              
              localStorage.setItem('arrayKey', JSON.stringify(retrievedArray));
              if (retrievedArray) {
                this.Samplearray = retrievedArray;
              }
        }
        }else{
          // 判断是否检测中（data=3|4）,显示进度
          this.reports = [];
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/omicsfit/getSample?name=${this.formData.name}&phone=${this.formData.phone}&openid=${this.$globalVar.userData.openId}&sampleCode=${this.formData.sampleCode}`,
            {}
          );
          // console.log('获取报告列表成功:', response.data);
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.reportsmsg = response.data;
            this.isReportBoxVisible = true;
          }else{
            this.reporterrmsg=response.data;
          }
        }
      } catch (error) {
        console.error('获取报告列表失败:', error);
      }
    },
    openReport() {
      this.fetchReports();
      this.isReportBoxVisible = true;
    },
    setOpenReport(sample) {
      if(sample){
        this.formData.name =sample.name;
        this.formData.phone =sample.phone;
        this.formData.sampleCode =sample.sampleCode;
        this.formData.openId =this.$globalVar.userData.openId;
      }      
      this.openReport();
    },
    closeReportBox() {
      this.isReportBoxVisible = false;
      this.selectedReport = null;
      this.reportsmsg=null;
      this.reports=null;
    }
  }
};
</script>

<style scoped>
table {
  padding: 5%;
}
td {
  text-align: left;
  padding: 7px 0px 7px 0px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0px;
  background: #F5F7FA;
  border-radius: 12px;
  width: 80%;
  margin: 10px;
  padding: 20px 20px 20px 20px;
  line-height: 30px;
  text-align: left;
  color: #1B2126;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size:16px;
}
 
label {
  color: #8D9092;
  font-weight: 200;
  font-size:15px;
}

a {
  color: #42b983;
}

.report-button {
  width: auto;
  background: #33DB81;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  border: 0px;
  text-decoration: none;
  float: right;
  line-height: 20px;
  letter-spacing: 1.76px;
  padding: 5px 10px 5px 10px ;
}
.unable-button {
  width: auto;
  background: #F5F7FA;
  border-radius: 8px;
  color: #8D9092;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  border: 0px;
  text-decoration: none;
  float: right;
  line-height: 20px;
  letter-spacing: 1.76px;
}

#reportbox {
  position: fixed; /* 固定位置 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-content {
  position: absolute; /* 固定位置 */
  bottom: 0px;
  left: 0px;
  width: 90%;
  flex-shrink: 0;
  background: #F5F7FA;
  border-radius: 32px 32px 0px 0px;
  padding: 20px;
  z-index: 999;
}

.close-button {
  position:absolute;
  top: 10px;
  right: 30px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  width: 24px;
height: 24px;
flex-shrink: 0;


}
.report-header {

  text-align: left;
}
.report-header img{
float: left;
}
.report-header label{
  line-height: 40px; /* 1 */
  color: #1B2126;
font-family: "Alibaba PuHuiTi 3.0";
font-size: 20px;
font-style: normal;
font-weight: 900;
letter-spacing: 1px;
padding-left: 10px;
line-height: 40px;
}
.report-content table{
background: #FFFFFF;
border-radius: 24px;
padding: 5px 20px 5px 20px;

}

.reportsdivhi {
    max-height: 400px;
  }

/* 响应式设计 */
@media (max-height: 932px) {
  .reportsdivhi {
    max-height: 600px;
  }
}
@media (max-height: 640px) {
  .reportsdivhi {
    max-height: 300px;
  }
}

.reportsdiv{
padding: 10px 0px 10px 0px;
line-height: 30px;

}

.reportsdiv span {
  color: #8D9092;
}

</style>