import { createRouter, createWebHistory } from 'vue-router'
import Index from '@/components/Index.vue'
import NewPage from '@/components/new.vue' // 引入新页面组件


const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/new',
    name: 'NewPage',
    component: NewPage // 添加新页面路由
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router