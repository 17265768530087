<template>
  <img id="topimg" alt="top img" src="./assets/top@3x.png" width="100%">
  <div id="topdiv" > </div>
  <Index msg="Welcome to Your Vue.js App"/>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import axios from 'axios';
import Index from './components/menu.vue'
import { authorizeWeChat } from './components/utils';

export default {
  name: 'App',
  components: {
    Index
  },
  data() {
    return {
     wxCode: ''
    };
  },
  async created() {
    console.log('localhost',location.href);
    const urlParams = new URLSearchParams(window.location.search);
    this.wxCode = urlParams.get('code');

    const savedData = localStorage.getItem('formData');
    if (savedData) {
      const data = JSON.parse(savedData);
      this.$globalVar.userData.name = data.name;
      this.$globalVar.userData.phone = data.phone;
      this.$globalVar.userData.sampleCode = data.sampleCode;
      this.$globalVar.userData.openId = data.openId;
    }

    if(this.$globalVar.userData.openId==''&&this.wxCode==null){
      // console.log('openId和wxCode为空，跳转授权');
      authorizeWeChat();
    }else if(this.$globalVar.userData.openId==''&&this.wxCode!=null){
      // console.log('openId为空，获取OpenID，wxCode：'+this.wxCode);
      const response =await axios.get(
            `${process.env.VUE_APP_API_URL}/wx/getOpenId?code=${this.wxCode}`,
            {}
          );
          if (response.data.openid) {
            this.$globalVar.userData.openId = response.data.openid;
            localStorage.setItem('formData', JSON.stringify(this.$globalVar.userData));
          } else {
            authorizeWeChat();
          }
    }else{
      console.log('openId：'+this.$globalVar.userData.openId);
    }
  }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
#app #app {
  background: #FFFFFF;
  border-radius: 0px;
  position:absolute; /* 固定位置 */
  top: 180px; /* 顶部对齐 */
  left: 0; /* 左侧对齐 */
  width: 100%; /* 宽度占满整个屏幕 */
  min-height: 200px;
  padding: 0px;
}
#topdiv {
    height: 140px; /* 调整高度 */
  }
/* 响应式设计 */
@media (max-width: 768px) {

  #app #app {
    top: 400px; /* 顶部对齐 */
  }

  #topdiv {
    height: 320px; /* 调整高度 */
  }
}

@media (max-width: 480px) {
  #app #app {
    top: 220px; /* 顶部对齐 */
  }

  #topdiv {
    height: 170px; /* 调整高度 */
  }
}
@media (max-width: 375px) {
  #app #app {
    top: 185px; /* 顶部对齐 */
  }

  #topdiv {
    height: 150px; /* 调整高度 */
  }
}
@media (max-width: 320px) {
  #app #app {
    top: 180px; /* 顶部对齐 */
  }

  #topdiv {
    height: 140px; /* 调整高度 */
  }
}

body {
  padding: 0px;
  margin: 0px;
}
body,input {
  color: #1B2126;
  font-family: "PingFang SC";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1.76px;
  text-align: center;
}
#topimg {
  position: fixed; /* 固定位置 */
  top: 0; /* 顶部对齐 */
  left: 0; /* 左侧对齐 */
  width: 100%; /* 宽度占满整个屏幕 */
  height: auto; /* 高度自适应 */
  z-index: -1; /* 确保图片在其他内容之下 */
}
input {
  background: #F5F7FA;
  border: 1px solid #EBEBF0;
  border-radius: 6px;
  text-align: left;
  width: 100%;
  padding: 0px;
}
input::placeholder {
  color: #AFB2B3;
  opacity: 1; /* Firefox */
  padding-left: 10px;
}
button {
  background: #33DB81;
  border-radius: 12px;
  color: #FFFFFF;
  font-size: 15px;
  text-align: center;
  padding: 7px;
  border: 0px;
  width: 90%;
  line-height: 35px;
  letter-spacing: 2px;
  }


</style>


