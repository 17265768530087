import { createApp } from 'vue'
import App from './App.vue'
import router from './router' // 引入路由

const app = createApp(App)
// 定义全局变量
app.config.globalProperties.$globalVar = {
    userData: {
        name: '',
        phone: '',
        sampleCode: '',
        openId: '',
        wxCode: ''
      },
    version: '1.0.0'
  }
app.use(router) // 使用路由

app.mount('#app')