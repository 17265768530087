<template>
  <div class="main">
    <form @submit.prevent="submitForm">
      <table style="width: 100%;">
        <tbody>
          <tr>
            <td>姓名：</td>
            <td  width="70%"><input class="input" v-model="name" placeholder="请填写" required /></td>
          </tr>
          <tr>
            <td>电话：</td>
            <td><input class="input" v-model="phone" placeholder="请填写" required /></td>
          </tr>
          <tr>
            <td>性别：</td>
            <td>
              <label class="radio-button">
                <input type="radio" v-model="sex" value="1" />
                <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12%"  viewBox="0 0 28.6 28.6001">
	<defs>
		<mask id="mask4474756306" style="mask-type:alpha">
			<path transform="matrix(1 0 0 1 0 0.000110626)" d="M0 0L28.6 0L28.6 28.6L0 28.6L0 0Z" fill-rule="nonzero" fill="rgb(196, 196, 196)"/>
		</mask>
	</defs>
	<g mask="url(#mask4474756306)">
		<path transform="matrix(0 -1 1 0 -4.57764e-05 28.6004)" d="M19.5005 9.75008C19.5005 4.36526 15.1351 0 9.75024 0C4.36533 0 0 4.36526 0 9.75008C0 15.1349 4.36533 19.5002 9.75024 19.5002C15.1351 19.5002 19.5005 15.1349 19.5005 9.75008ZM7.22103 3.75948Q8.4256 3.25 9.75024 3.25Q11.0749 3.25 12.2794 3.75948Q13.4454 4.25262 14.3466 5.15385Q15.2479 6.05507 15.741 7.22097Q16.2505 8.4255 16.2505 9.75008Q16.2505 11.0747 15.741 12.2792Q15.2479 13.4451 14.3466 14.3463Q13.4454 15.2475 12.2794 15.7407Q11.0749 16.2502 9.75024 16.2502Q8.4256 16.2502 7.22103 15.7407Q6.05509 15.2475 5.15386 14.3463Q4.25262 13.4451 3.75948 12.2792Q3.25 11.0747 3.25 9.75008Q3.25 8.42549 3.75948 7.22097Q4.25262 6.05507 5.15386 5.15385Q6.0551 4.25262 7.22103 3.75948Z" fill-rule="evenodd" fill="rgb(27, 33, 38)"/>
		<path transform="matrix(0.999999 0 0 0.999999 18.2 2.60014)" d="M-1.755 0C-1.755 0.969251 -0.969251 1.755 0 1.755L6.04492 1.755L6.04492 7.8C6.04492 8.76925 6.83067 9.555 7.79992 9.555C8.76917 9.555 9.55492 8.76925 9.55492 7.8L9.55492 0Q9.55492 -0.0862176 9.54647 -0.17202Q9.53802 -0.257823 9.5212 -0.342383Q9.50438 -0.426944 9.47935 -0.50945Q9.45432 -0.591955 9.42133 -0.671609Q9.38834 -0.751264 9.34769 -0.827301Q9.30705 -0.903338 9.25915 -0.975026Q9.21125 -1.04671 9.15655 -1.11336Q9.10186 -1.18001 9.04089 -1.24097Q8.97993 -1.30194 8.91328 -1.35663Q8.84663 -1.41133 8.77495 -1.45923Q8.70326 -1.50713 8.62722 -1.54777Q8.55118 -1.58841 8.47153 -1.62141Q8.39187 -1.6544 8.30937 -1.67943Q8.22686 -1.70446 8.1423 -1.72128Q8.05774 -1.7381 7.97194 -1.74655Q7.88614 -1.755 7.79992 -1.755L0 -1.755C-0.969251 -1.755 -1.755 -0.969251 -1.755 0Z" fill-rule="evenodd" fill="rgb(27, 33, 38)"/>
		<path transform="matrix(0.999999 0 0 0.999999 19.5 2.6002)" d="M7.74088 -1.24097C7.05551 -1.92634 5.9443 -1.92634 5.25893 -1.24097L-1.24097 5.25895C-1.92634 5.94431 -1.92634 7.05553 -1.24097 7.74089C-0.555606 8.42626 0.55561 8.42626 1.24097 7.74089L7.74088 1.24097C8.42624 0.555606 8.42624 -0.55561 7.74088 -1.24097Z" fill-rule="evenodd" fill="rgb(27, 33, 38)"/>
	</g>
</svg>
男</span>
              </label>
              <label class="radio-button" style="padding-left: 4%;">
                <input type="radio" v-model="sex" value="0" />
                <span><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14%"  viewBox="0 0 31.2 31.2">
	<defs>
		<mask id="mask1344984727" style="mask-type:alpha">
			<path transform="matrix(1 0 0 1 0 4.95911e-05)" d="M0 0L31.2 0L31.2 31.2L0 31.2L0 0Z" fill-rule="nonzero" fill="rgb(196, 196, 196)"/>
		</mask>
	</defs>
	<g mask="url(#mask1344984727)">
		<path transform="matrix(0.707107 -0.707107 0.707107 0.707107 -1.56 11.6845)" d="M18.72 9.36C18.72 4.19061 14.5294 0 9.36 0C4.19061 0 0 4.19061 0 9.36C0 14.5294 4.19061 18.72 9.36 18.72C14.5294 18.72 18.72 14.5294 18.72 9.36ZM6.98271 3.72882Q8.11477 3.25 9.36 3.25Q10.6052 3.25 11.7373 3.72882Q12.8332 4.19235 13.6804 5.03958Q14.5277 5.88681 14.9912 6.98271Q15.47 8.11477 15.47 9.36Q15.47 10.6052 14.9912 11.7373Q14.5277 12.8332 13.6804 13.6804Q12.8332 14.5276 11.7373 14.9912Q10.6052 15.47 9.36 15.47Q8.11477 15.47 6.98271 14.9912Q5.88681 14.5276 5.03958 13.6804Q4.19235 12.8332 3.72882 11.7373Q3.25 10.6052 3.25 9.36Q3.25 8.11477 3.72882 6.98271Q4.19235 5.88681 5.03958 5.03958Q5.88681 4.19235 6.98271 3.72882Z" fill-rule="evenodd" fill="rgb(141, 144, 146)"/>
		<path transform="matrix(0.707107 -0.707107 0.707107 0.707107 14.3244 24.9215)" d="M1.872 0C0.838123 0 0 0.838123 0 1.872L0 1.87481C0 2.90868 0.838123 3.744 1.872 3.744L13.104 3.744C14.1379 3.744 14.976 2.90868 14.976 1.87481L14.976 1.872C14.976 0.838123 14.1379 0 13.104 0L1.872 0Z" fill-rule="nonzero" fill="rgb(141, 144, 146)"/>
		<path transform="matrix(0.707107 0.707107 -0.707107 0.707107 20.9433 18.303)" d="M1.872 0C0.838123 0 0 0.838123 0 1.872L0 1.87481C0 2.90869 0.838123 3.744 1.872 3.744L9.36 3.744C10.3939 3.744 11.232 2.90869 11.232 1.87481L11.232 1.872C11.232 0.838123 10.3939 0 9.36 0L1.872 0Z" fill-rule="nonzero" fill="rgb(141, 144, 146)"/>
	</g>
</svg>
女</span>
              </label>

            </td>
          </tr>
          <tr>
            <td>生日：</td>
            <td><div class="date-input"><input type="date" id="birth" v-model="birth" placeholder="请选择生日" required /></div></td>
          </tr>
          <tr>
            <td>样本条码：</td>
            <td><input class="input" v-model="sampleCode"  placeholder="请填写"  required /></td>
          </tr>
        </tbody>
      </table>
      <button type="submit">提交</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'; // 确保已安装 axios

export default {
  name: 'ReportNew',
  props: {
    msg: String
  },
  data() {
    return {
      name: '',
      phone: '',
      sex: '',
      birth: '',
      birthAddress: '',
      sampleCode: ''
    };
  },
  mounted() {
    // 从 localStorage 加载数据
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      const data = JSON.parse(savedData);
      this.name = data.name;
      this.phone = data.phone;
      this.sex = data.sex;
      this.birth = data.birth;
      this.sampleCode = data.sampleCode;
    }
  },
  methods: {
    submitForm() {
      const data = {
        name: this.name,
        phone: this.phone,
        openId: this.$globalVar.userData.openId, 
        sampleCode: this.sampleCode,
        sex: this.sex,
        birth: this.birth,
        birthAddress: '无'
      };

      // 保存数据到 localStorage
      localStorage.setItem('formData', JSON.stringify(data));

      axios.post(`${process.env.VUE_APP_API_URL}/omicsfit/Bind`, data, {
        headers: {
          'User-Agent': 'Apifox/1.0.0 (https://apifox.com)',
          'Accept': '*/*',
          'Host': 'mp.jkx.city',
          'Connection': 'keep-alive'
        }
      })
      .then(response => {
        alert('提示:' + response.data);
        // console.log('提交成功：', response);
        if("样本不存在"==response.data||"样本已经在其他账号下绑定"==response.data){
          return;
        }
        // 可以在这里添加提交成功后的处理逻辑
        var retrievedArray = localStorage.getItem('arrayKey');
        retrievedArray = JSON.parse(retrievedArray);
        if(retrievedArray){
          for (let index = 0; index < retrievedArray.length; index++) {
            if(data.sampleCode==retrievedArray[index].sampleCode){
              return;
          }
            
          }
          retrievedArray.push(data);
        }else{
            retrievedArray = [];
            retrievedArray.push(data);
          }
          
          localStorage.setItem('arrayKey', JSON.stringify(retrievedArray));
          this.$router.push('/?sampleCode='+data.sampleCode); 
        
      })
      .catch(error => {
        alert('提交失败请重试。原因:' + error.message + '请重新提交。');
        // 可以在这里添加提交失败后的处理逻辑
      });
    }
  }
}
</script>

<style scoped>
table {
  padding: 5%;
}
td {
  text-align: left;
  padding: 7px 0px 7px 0px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.main {
  padding: 0px;
}
.input {
  width: 100%;
  padding: 0px;
}
#birth {
    padding: 0px;margin: 0px;
    border: 0px;
}
.radio-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  width: 48%;
}

.radio-button input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-button span {
  display: inline-block;
  width: 100%;
  letter-spacing: 1.76px;
  background: #F5F7FA;
  border: 1px solid #EBEBF0;
  border-radius: 6px;
  text-align: center;
  padding: 0px;
  transition: background-color 0.3s;
}

.radio-button input:checked + span {
  background: rgba(25, 208, 130, 0.07);
  border: 1px solid #19D082;
}
.date-input {
  position: relative;
  width: 100%;
  background: #F5F7FA;
  border: 1px solid #EBEBF0;
  border-radius: 6px;
  text-align: left;
}



.date-input input[type="date"]:before {
  content: '请选择日期';
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
  pointer-events: none;
}

.date-input input[type="date"]:focus:before,
.date-input input[type="date"]:valid:before {
  content: '';
}
</style>